import React from "react";

const Header = () => {
  return (
    <>
      <img src={require("../imgs/logo.png")} alt="logo" width={600} />
    </>
  );
};

export default Header;
